<template>
  <tr>
    <td class="profileInvoices__tableCell">
      {{ date }}
    </td>

    <td class="profileInvoices__tableCell profileInvoices__tableCell--hiddenMobile">
      {{ invoice.number }}
    </td>

    <td class="profileInvoices__tableCell">
      {{ invoice.amount }}
    </td>

    <td class="profileInvoices__tableCell">
      <font-awesome-icon
        v-if="invoice.status === 'paid'"
        icon="check"
        class="profileInvoices__invoiceStatus profileInvoices__invoiceStatus--paid"
      />

      <font-awesome-icon
        v-if="invoice.status === 'open'"
        v-bind:icon="['fal', 'exclamation-triangle']"
        class="profileInvoices__invoiceStatus profileInvoices__invoiceStatus--open"
      />
    </td>

    <td class="profileInvoices__tableCell">
      <span
        v-if="invoice.link"
        v-on:click="onDownloadInvoice"
        class="profileInvoices__downloadLink"
      >
        <font-awesome-icon
          icon="cloud-arrow-down"
          class=""
        />
      </span>

      <base-loader
        modifiers="tiny"
        v-bind:class="{
          'profileInvoices__loader': true,
          'profileInvoices__loader--visible': isDownloading,
        }"
      />
    </td>
</tr>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import BaseLoader from '@/components/BaseLoader';

export default {
  components: {
    BaseLoader,
  },

  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isDownloading: false,
    };
  },

  computed: {
    date() {
      return moment(this.invoice.date).format('DD-MM-YYYY');
    },
  },

  methods: {
    ...mapActions('invoices', ['download']),

    onDownloadInvoice() {
      if (!this.isDownloading) {
        this.isDownloading = true;

        this.download(this.invoice.link)
          .then(data => {
            this.isDownloading = false;

            const a = document.createElement('a');

            a.href = window.URL.createObjectURL(new Blob([data]), { type: 'application/pdf' });
            a.download = `Factuur-${this.invoice.number}.pdf`;
            a.click();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileInvoices__invoiceStatus {
  &--open {
    color: $color-orange--dark;
  }

  &--paid {
    color: $color-mint;
  }
}

.profileInvoices__downloadLink {
  cursor: pointer;
}

.profileInvoices__loader {
  visibility: hidden;

  &--visible {
    visibility: visible;
  }
}
</style>