<template>
  <RenderlessPagination
    v-bind:data="data"
    v-bind:limit="limit"
    v-bind:keep-length="keepLength"
    v-on:pagination-change-page="onPaginationChangePage"
    v-slot="slotProps"
  >
    <div class="pagination">
      <nav
        v-if="slotProps.computed.total > slotProps.computed.perPage"
        v-bind="$attrs"
        aria-label="Pagination"
        class="pagination__nav"
      >
        <button
          v-if="slotProps.computed.prevPageUrl"
          v-on="slotProps.prevButtonEvents"
          class="pagination__pageButton"
        >
          <slot name="prev-nav">
            Previous
          </slot>
        </button>

        <button
          v-bind:aria-current="slotProps.computed.currentPage ? 'page' : null"
          v-for="(page, key) in slotProps.computed.pageRange"
          v-bind:key="key"
          v-on="slotProps.pageButtonEvents(page)"
          v-bind:class="{
            'pagination__pageItem': true,
            'pagination__pageItem--active': page === slotProps.computed.currentPage,
          }"
        >
          {{ page }}
        </button>

        <button
          v-if="slotProps.computed.nextPageUrl"
          v-on="slotProps.nextButtonEvents"
          class="pagination__pageButton"
        >
          <slot name="next-nav">
            Next
          </slot>
        </button>
      </nav>
    </div>
  </RenderlessPagination>
</template>

<script>
import RenderlessPagination from 'laravel-vue-pagination/src/RenderlessPagination';

export default {
  inheritAttrs: false,

  emits: ['pagination-change-page'],

  components: {
    RenderlessPagination,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },

    limit: {
      type: Number,
      default: 0,
    },

    keepLength: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onPaginationChangePage(page) {
      this.$emit('pagination-change-page', page);
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.pagination {
  margin: rem(24px) 0 0 0;
  text-align: center;
}

.pagination__nav {
  margin: 0;
  padding: 0;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  background: $color-beige;
  border-radius: 40px;
}

.pagination__pageButton,
.pagination__pageItem {
  @include small;
  display: block;
  margin: 0;
  padding: rem(8px);
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  text-decoration: none;
  color: $color-text;

  &:hover:not(.pagination__pageItem--active) {
    background: $color-beige--dark;
  }

  &--active {
    background: $color-green;
    color: $color-white;
  }
}
</style>