<template>
  <div class="profileInvoices">
    <div class="profileInvoices__title">
      {{ $t('profileInvoices.title') }}
    </div>

    <div
      v-if="debtorLink"
      class="profileInvoices__openInvoices"
    >
      <p class="profileInvoices__notice profileInvoices__notice--red">
        {{ $t('profileInvoices.openInvoicesNotice', { num: numOpenInvoices }) }}
      </p>

      <base-button
        modifiers="primary"
        class="profileInvoices__button"
        v-bind:href="debtorLink"
        target="_blank"
      >
        {{ $t('profileInvoices.payButtonLabel') }}
      </base-button>
    </div>

    <table class="profileInvoices__table">
      <thead class="profileInvoices__tableHead">
        <tr>
          <td class="profileInvoices__tableCell">
            {{ $t('profileInvoices.dateLabel') }}
          </td>

          <td class="profileInvoices__tableCell profileInvoices__tableCell--hiddenMobile">
            {{ $t('profileInvoices.featureLabel') }}
          </td>

          <td class="profileInvoices__tableCell">
            {{ $t('profileInvoices.amountLabel') }}
          </td>

          <td class="profileInvoices__tableCell">
            {{ $t('profileInvoices.statusLabel') }}
          </td>

          <td class="profileInvoices__tableCell" />
        </tr>
      </thead>

      <tbody class="profileInvoices__tableBody">
        <profile-invoices-item
          v-for="(invoice, index) in invoices"
          v-bind:key="index"
          v-bind:invoice="invoice"
        />
      </tbody>
    </table>

    <base-pagination
      v-if="invoices.length"
      v-bind:data="pagination"
      v-on:pagination-change-page="fetchInvoices"
    >
      <template v-slot:prev-nav>
        <span>
          <font-awesome-icon icon="chevron-left" />
        </span>
      </template>

      <template v-slot:next-nav>
        <span>
          <font-awesome-icon icon="chevron-right" />
        </span>
      </template>
    </base-pagination>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePagination from '@/components/BasePagination';
import BaseButton from '@/components/BaseButton';
import BaseLoader from '@/components/BaseLoader';
import ProfileInvoicesItem from '@/components/profile/ProfileInvoicesItem';

export default {
  components: {
    BasePagination,
    BaseButton,
    BaseLoader,
    ProfileInvoicesItem,
  },

  computed: {
    ...mapState('invoices', ['invoices', 'pagination', 'numOpenInvoices', 'debtorLink']),
  },

  mounted() {
    this.fetchInvoices();
  },

  methods: {
    ...mapActions({
      fetchInvoices: 'invoices/fetchAll',
    }),
  },
};
</script>

<style lang="scss">
@import "@/scss/lib";

.profileInvoices__tableCell {
  padding: rem(2px) rem(8px);

  @include mobile {
    &--hiddenMobile {
      display: none;
    }
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileInvoices__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileInvoices__openInvoices {
  margin: 0 0 rem(16px) 0;
}

.profileInvoices__notice {
  @include small;
  margin: 0 0 rem(10px) 0;
  padding: rem(16px);
  border-radius: 6px;

  &--red {
    background: rgba($color-error-dark, 0.1);
  }
}

.profileInvoices__table {
  width: 100%;
}

.profileInvoices__tableHead {
  @include note;
  color: $color-grey;
}

.profileInvoices__tableBody {
  @include small;
}
</style>
